/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/qtip2@3.0.3/dist/jquery.qtip.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/i18n/nl.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
